<script>
import axios from "axios";
import PDFViewer from "../../layouts/pdf-viewer.vue";
import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    PDFViewer,
    editor: Editor,
  },
  data() {
    return {
      list_soal: [],
      selectedItem: null,
      fileEmbed: null,
      isRichText: false,
      contentRichText: "Loading...",
      intake_type: this.$route.params.intake_type,
      url_backend_pdf_js: process.env.VUE_APP_BACKEND_URL + "/pdf.js/web/viewer.html?file=",
      url_backend_file: process.env.VUE_APP_BACKEND_URL,
      jawaban_tujuan: "",
    };
  },
  mounted() {
    this.fetchSoal();
  },
  methods: {
    toggleItem(item) {
      if (this.selectedItem === item) {
        this.selectedItem = null;
        this.fileEmbed = null;
        this.isRichText = false;
        this.contentRichText = "Loading...";
      } else {
        this.selectedItem = item;
        if (this.intake_type == "intaketype7") {
          this.fileEmbed = null;
          this.isRichText = true;
          this.contentRichText = item.jawaban;
        } else if (item.tipe == "Rich Text") {
          this.fileEmbed = null;
          this.isRichText = true;
          this.contentRichText = item.rich_text;
        } else if (item.tipe == "Video" || item.tipe == "PPT" || item.tipe == "Excel") {
          this.fileEmbed = item.link_yt;
          this.isRichText = false;
          this.contentRichText = "Loading...";
        } else {
          this.fileEmbed = this.url_backend_pdf_js + this.url_backend_file + item.file;
          this.isRichText = false;
          this.contentRichText = "Loading...";
        }
      }
    },
    fetchSoal() {
      let self = this;
      const apiUrl = process.env.VUE_APP_BACKEND_URL_VERSION + `soal/${this.$route.params.id_jadwal_detail}/${this.$route.params.intake_type}`; // Replace with your API endpoint URL
      axios
        .get(apiUrl)
        .then((response) => {
          var responseData = response.data;
          if (this.$route.params.intake_type == "intaketype7" || this.$route.params.intake_type == "intaketype8") {
            if (this.intake_type == "intaketype7") {
              if (responseData.data.data.jawaban_dac_tujuan != null) {
                self.jawaban_tujuan = responseData.data.data.jawaban_dac_tujuan.jawaban;
              }
            } else {
              self.list_soal = responseData.data.data.list_soal;
            }
          } else if (
            this.$route.params.intake_type == "intaketype1" ||
            this.$route.params.intake_type == "intaketype2" ||
            this.$route.params.intake_type == "intaketype3" ||
            this.$route.params.intake_type == "intaketype4" ||
            this.$route.params.intake_type == "intaketype5" ||
            this.$route.params.intake_type == "intaketype6"
          ) {
            self.list_soal = responseData.data.data;
          } else {
            self.list_soal = responseData.data.data.list_soal;
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
  },
};
</script>
<template>
  <div v-if="intake_type == 'intaketype7'">
    <div>
      <editor
        api-key="m1l04baz8dzyto2x8wz86rypvdo0ev13809mfj6r4a2pvcdi"
        :init="{
          height: 500,
          menubar: false,
          plugins: [],
          toolbar: '',
        }"
        :disabled="true"
        v-model="jawaban_tujuan"
      />
    </div>
  </div>
  <div v-else>
    <div class="accordion" id="accordionExample">
      <div v-if="this.$route.params.intake_type == 'intaketype8'">
        <div v-for="(item, key, index) in list_soal" :key="key">
          <div class="accordion-item">
            <h2 class="accordion-header" :id="'heading' + item.id">
              <button
                class="accordion-button"
                type="button"
                @click="toggleItem(item)"
                :class="{ collapsed: selectedItem !== item }"
                :aria-expanded="selectedItem === item"
                :aria-controls="'collapse' + item.id"
              >
                Materi {{ index + 1 }}
              </button>
            </h2>
            <div :id="'collapse' + item.id" class="accordion-collapse collapse" :class="{ show: selectedItem === item }" :aria-labelledby="'heading' + item.id" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div v-if="isRichText == true">
                  <editor
                    api-key="m1l04baz8dzyto2x8wz86rypvdo0ev13809mfj6r4a2pvcdi"
                    :init="{
                      height: 500,
                      menubar: false,
                      plugins: [],
                      toolbar: '',
                    }"
                    :disabled="true"
                    v-model="contentRichText"
                  />
                </div>
                <div v-else>
                  <PDFViewer :pdfUrl="fileEmbed" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-for="(item, index) in list_soal" :key="index">
          <div class="accordion-item">
            <h2 class="accordion-header" :id="'heading' + item.id">
              <button
                class="accordion-button"
                type="button"
                @click="toggleItem(item)"
                :class="{ collapsed: selectedItem !== item }"
                :aria-expanded="selectedItem === item"
                :aria-controls="'collapse' + item.id"
              >
                Materi {{ index + 1 }}
              </button>
            </h2>
            <div :id="'collapse' + item.id" class="accordion-collapse collapse" :class="{ show: selectedItem === item }" :aria-labelledby="'heading' + item.id" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div v-if="isRichText == true">
                  <editor
                    api-key="m1l04baz8dzyto2x8wz86rypvdo0ev13809mfj6r4a2pvcdi"
                    :init="{
                      height: 500,
                      menubar: false,
                      plugins: [],
                      toolbar: '',
                    }"
                    :disabled="true"
                    v-model="contentRichText"
                  />
                </div>
                <div v-else>
                  <PDFViewer :pdfUrl="fileEmbed" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
